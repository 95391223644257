import {Button} from '@components/ui/button/Button.tsx'
import {Trans, useTranslation} from 'react-i18next'
import {zodResolver} from '@hookform/resolvers/zod'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {Controller, useForm} from 'react-hook-form'
import {InputText} from '@/components/commons/input-text/InputText'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {
    ProfileCreateRequest,
    ProfileFormSchema,
    ProfileFormType,
    ProfileRequest,
    ProfileResponse
} from '@/features/profile/ProfileSchema'
import {createProfile, updateProfile} from '@/features/profile/profile'
import Select, {SelectValue} from '@/components/commons/select/Select'
import {RoleType, roleOptions, statusOptions} from '@/utilities/constants/commons'
import {StyledFormInputContainer, StyledProfileForm, StyledRecoveryPasswordText} from './style'
import toast from 'react-hot-toast'
import {useLocation, useNavigate} from 'react-router-dom'
import {useAuthStore} from '@/features/auth/store/store'
import {useState} from 'react'
import {adminChangePassword} from '@/features/auth/services/auth.http'

interface ProfileFormProps {
    data?: ProfileResponse
    isFetching?: boolean
}

export const ProfileForm = ({data, isFetching}: ProfileFormProps) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    const user = useAuthStore(state => state.user)
    const setUser = useAuthStore(state => state.setUser)
    const navigate = useNavigate()
    const [recoveryPasswordField, setRecoveryPasswordField] = useState(false)
    const location = useLocation()

    const {
        handleSubmit,
        register,
        formState: {errors, touchedFields, dirtyFields},
        control
    } = useForm<ProfileFormType>({
        defaultValues: {
            firstName: data?.firstName || undefined,
            lastName: data?.lastName || undefined,
            email: data?.email || undefined,
            role: roleOptions.find(i => i.value === data?.role) || roleOptions[0],
            isActive: statusOptions.find(i => i.value === data?.isActive.toString()) || statusOptions[1],
            password: undefined,
            passwordConfirmation: undefined
        },
        resolver: zodResolver(ProfileFormSchema),
        mode: 'onBlur'
    })

    const createMutation = useMutation({
        mutationKey: ['create-profile'],
        mutationFn: (data: ProfileCreateRequest) => createProfile(data),
        onSuccess: data => {
            toast.success('Profilo creato con successo')
            queryClient.invalidateQueries({queryKey: ['userTable'], refetchType: 'all'})
            queryClient.invalidateQueries({queryKey: ['getProfile', data.id.toString()], refetchType: 'all'})
            navigate(`/users/${data?.firstName.toLowerCase()}-${data?.lastName.toLowerCase()}?id=${data.id}`)
        },
        onError: e => {
            errorHandler(e)
        }
    })

    const updateMutation = useMutation({
        mutationKey: ['update-profile'],
        mutationFn: (data: ProfileRequest) => updateProfile(data),
        onSuccess: data => {
            toast.success('Profilo aggiornato con successo')
            queryClient.invalidateQueries({queryKey: ['userTable'], refetchType: 'all'})
            queryClient.invalidateQueries({queryKey: ['getProfile', data.id.toString()], refetchType: 'all'})
            if (user?.id === data.id)
                setUser({
                    id: data?.id,
                    email: data?.email,
                    firstName: data?.firstName,
                    lastName: data?.lastName,
                    role: data?.role,
                    isActive: data?.isActive,
                    lastLoginAt: user?.lastLoginAt || '',
                    createdAt: user?.createdAt || '',
                    updatedAt: Date.now().toString()
                })
        },
        onError: e => {
            errorHandler(e)
        }
    })

    const changePasswordMutation = useMutation({
        mutationKey: ['change-password'],
        mutationFn: (data: {id: number, password: string; passwordConfirmation: string}) => adminChangePassword(data),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['userTable'], refetchType: 'all'})
            toast.success('Password aggiornata con successo')
            setRecoveryPasswordField(false)
        },
        onError: e => {
            errorHandler(e)
        }
    })

    const onSubmit = (formData: ProfileFormType) => {
        const {email, firstName, lastName, role, isActive, password, passwordConfirmation} = formData

        if (!data) {
            createMutation.mutate({
                email: email,
                firstName: firstName,
                lastName: lastName,
                role: role?.value === RoleType.editor ? RoleType.editor : RoleType.admin,
                isActive: isActive?.value === statusOptions[0].value ? true : false,
                password: password,
                passwordConfirmation: passwordConfirmation
            })
        } else {
            if (recoveryPasswordField) {
                if (
                    dirtyFields.firstName ||
                    dirtyFields.lastName ||
                    dirtyFields.email ||
                    dirtyFields.role ||
                    dirtyFields.isActive
                ) {
                    updateMutation.mutate(
                        {
                            id: data?.id || 0,
                            email: email,
                            firstName: firstName,
                            lastName: lastName,
                            role: role?.value === RoleType.editor ? RoleType.editor : RoleType.admin,
                            isActive: isActive?.value === statusOptions[0].value ? true : false
                        },
                        {
                            onSuccess: () => {
                                changePasswordMutation.mutate({
                                    id: data?.id,
                                    password: password || '',
                                    passwordConfirmation: passwordConfirmation || ''
                                })
                            }
                        }
                    )
                } else {
                    changePasswordMutation.mutate({
                        id: data?.id,
                        password: password || '',
                        passwordConfirmation: passwordConfirmation || ''
                    })
                }
            } else {
                updateMutation.mutate({
                    id: data?.id || 0,
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    role: role?.value === RoleType.editor ? RoleType.editor : RoleType.admin,
                    isActive: isActive?.value === statusOptions[0].value ? true : false
                })
            }
        }
    }

    return (
        <StyledProfileForm onSubmit={handleSubmit(onSubmit)}>
            <p>{t('profile:data')}</p>
            <StyledFormInputContainer>
                <InputText
                    label={t('profile:form:firstName')}
                    type={'text'}
                    touched={touchedFields.firstName}
                    errorMessage={errors?.firstName?.message || ''}
                    placeholder={t('profile:form:firstName')}
                    {...register('firstName')}
                    width={'360px'}
                    autoComplete={'off'}
                    name={'firstName'}
                />

                <InputText
                    label={t('profile:form:lastName')}
                    type={'text'}
                    touched={touchedFields.lastName}
                    errorMessage={errors?.lastName?.message || ''}
                    placeholder={t('profile:form:lastName')}
                    {...register('lastName')}
                    width={'360px'}
                    autoComplete={'off'}
                    name={'lastName'}
                />
            </StyledFormInputContainer>
            <StyledFormInputContainer>
                <InputText
                    label={t('profile:form:email')}
                    type={'text'}
                    touched={touchedFields.email}
                    errorMessage={errors?.email?.message || ''}
                    placeholder={t('profile:form:email')}
                    {...register('email')}
                    width={'360px'}
                    autoComplete={'off'}
                    name={'email'}
                />
                <Controller
                    render={({field: {onChange, value}}) => (
                        <Select
                            {...register(`role`)}
                            value={value}
                            onChange={newValue => {
                                onChange(newValue as SelectValue[])
                            }}
                            size={'medium'}
                            label={t('profile:form:role')}
                            isClearable={false}
                            isSearchable={false}
                            errorMessage={errors?.role?.message || ''}
                            placeholder={t('profile:form:role')}
                            options={roleOptions}
                            hideSelectedOptions={false}
                            disabled={user?.role === RoleType.editor}
                        />
                    )}
                    control={control}
                    name={'role'}
                />
            </StyledFormInputContainer>
            <Controller
                render={({field: {onChange, value}}) => (
                    <Select
                        {...register(`isActive`)}
                        value={value}
                        onChange={newValue => {
                            onChange(newValue as SelectValue[])
                        }}
                        size={'medium'}
                        label={t('profile:form:status')}
                        isClearable={false}
                        isSearchable={false}
                        errorMessage={errors?.isActive?.message || ''}
                        placeholder={t('profile:form:role')}
                        options={statusOptions}
                        hideSelectedOptions={false}
                        disabled={user?.role === RoleType.editor}
                    />
                )}
                control={control}
                name={'isActive'}
            />
            <p>{t('profile:password')}</p>
            {!data && (
                <StyledFormInputContainer>
                    <InputText
                        label={t('change:form:password')}
                        type={'password'}
                        touched={touchedFields.password}
                        errorMessage={errors?.password?.message || ''}
                        placeholder={t('change:form:password')}
                        {...register('password')}
                        visibilityToggle={true}
                        width={'360px'}
                        autoComplete={'off'}
                        name={'password'}
                    />

                    <InputText
                        label={t('change:form:passwordConfirmation')}
                        type={'password'}
                        touched={touchedFields.passwordConfirmation}
                        errorMessage={errors?.passwordConfirmation?.message || ''}
                        placeholder={t('change:form:passwordConfirmation')}
                        {...register('passwordConfirmation')}
                        visibilityToggle={true}
                        width={'360px'}
                        autoComplete={'off'}
                        name={'passwordConfirmation'}
                    />
                </StyledFormInputContainer>
            )}

            {recoveryPasswordField && data ? (
                <StyledFormInputContainer>
                    <InputText
                        label={t('change:form:password')}
                        type={'password'}
                        touched={touchedFields.password}
                        errorMessage={errors?.password?.message || ''}
                        placeholder={t('change:form:password')}
                        {...register('password')}
                        visibilityToggle={true}
                        width={'360px'}
                        autoComplete={'off'}
                        name={'password'}
                    />

                    <InputText
                        label={t('change:form:passwordConfirmation')}
                        type={'password'}
                        touched={touchedFields.passwordConfirmation}
                        errorMessage={errors?.passwordConfirmation?.message || ''}
                        placeholder={t('change:form:passwordConfirmation')}
                        {...register('passwordConfirmation')}
                        visibilityToggle={true}
                        width={'360px'}
                        autoComplete={'off'}
                        name={'passwordConfirmation'}
                    />
                </StyledFormInputContainer>
            ) : (
                data && (
                    <StyledRecoveryPasswordText>
                        <Trans
                            t={t}
                            i18nKey={
                                location.pathname === '/profile'
                                    ? 'profile:password_cta_reset'
                                    : 'profile:forgot_password_cta'
                            }
                            components={[<p></p>, <span onClick={() => setRecoveryPasswordField(true)}></span>]}
                        />
                    </StyledRecoveryPasswordText>
                )
            )}

            <Button
                type="submit"
                name="Submit"
                variant={'primary'}
                size={'lg'}
                style={{maxWidth: '138px', marginTop: '24px'}}
                disabled={isFetching || Object.keys(dirtyFields).length === 0}
            >
                {!updateMutation.isPending && !createMutation.isPending ? (
                    `${t(data ? 'profile:save' : 'profile:create')}`
                ) : (
                    <Spinner size={16} />
                )}
            </Button>
        </StyledProfileForm>
    )
}
