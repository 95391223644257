import React from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {useTranslation} from 'react-i18next'

import {useAuthStore} from '@/features/auth/store/store'
import {PageHero} from '@/components/commons/page-hero/PageHero'
import {ProfileForm} from '@/components/commons/profile-form/ProfileForm'
import {useLocation} from 'react-router-dom'
import {useQuery} from '@tanstack/react-query'
import toast from 'react-hot-toast'
import {ProfileResponse} from '@/features/profile/ProfileSchema'
import {getProfileDetail} from '@/features/profile/profile'
import {ContainerName} from '@/utilities/constants/commons'

export const Profile: React.FC = () => {
    const {t} = useTranslation()

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get('id')

    const newUserPath = location.pathname.includes(ContainerName.newUser)

    const {data, error, isFetching} = useQuery<ProfileResponse>({
        queryKey: ['getProfile', id],
        queryFn: () => getProfileDetail('regular', id ? +id : user?.id)
    })

    if (error) {
        toast.error(error.message)
    }

    const user = useAuthStore(state => state.user)

    return (
        <Container>
            <PageHero title={t(`profile:title`)} subtitle={''} />
            {!newUserPath && isFetching == false && <ProfileForm data={data} isFetching={isFetching} />}
            {newUserPath && <ProfileForm isFetching={isFetching} />}
        </Container>
    )
}

Profile.displayName = 'Profile'
